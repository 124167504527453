import { schema } from 'normalizr';
import attachmentSchema from './attachment';
import userSchema from './user';
import postSchema from './post';
import groupSchema from './group';
import eventSchema from './event';
import fileGroupSchema from './fileGroup';
import memberSchema from './member';
import learningCourseSchema from './learningCourse';
import pageSchema from './page';
import documentFolderSchema from './documentFolder';
import documentSectionSchema from './documentSection';

const unknownSchema = new schema.Entity('unknown');

export default {
  data: entity => ({
    attachment: attachmentSchema,
    course: learningCourseSchema,
    event: eventSchema,
    document_folder: documentFolderSchema,
    document_section: documentSectionSchema,
    file_group: fileGroupSchema,
    group: groupSchema,
    member: memberSchema,
    page: pageSchema,
    post: postSchema,
    user: userSchema,
  })[entity.type] || unknownSchema,
};
