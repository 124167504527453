import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getFolderEntities = ({ entities: { documentFolders } }) => documentFolders;
const getFolderVariantEntities = ({ entities: { documentFolderVariants } }) => documentFolderVariants;

const getFoldersForMember = ({ documentFolder: { foldersForMember } }) => selectPagination(foldersForMember, null, 'folderIds');

const getFolderForMember = createSelector(
  [getFolderEntities],
  folders => memoize(
    folderId => folders?.[folderId],
  ),
);

const getFoldersForAdmin = ({ documentFolder: { foldersForAdmin } }) => selectPagination(foldersForAdmin, null, 'folderIds');

const getFolderForAdmin = createSelector(
  [getFolderEntities],
  folders => memoize(
    folderId => folders?.[folderId],
  ),
);

const getFolderVariantForAdmin = createSelector(
  [getFolderVariantEntities],
  folderVariants => memoize(
    folderVariantId => folderVariants?.[folderVariantId],
  ),
);

const getFolderVariantForMember = getFolderVariantForAdmin;

export default {
  getFoldersForMember,
  getFolderForMember,
  getFoldersForAdmin,
  getFolderForAdmin,
  getFolderVariantForAdmin,
  getFolderVariantForMember,
};
